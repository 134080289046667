<template>
  <b-modal
    id="approve-request-modal"
    ref="approve-request-modal"
    modal-class="modal-primary"
    title="กำหนดเปอร์เซนต์รายได้จากค่าธรรมเนียม"
    hide-footer
    centered
  >
    <validation-observer ref="approveRequestForm">
      <b-form @submit.prevent="onSubmit">
        <validation-provider
          v-slot="{ errors }"
          name="rate"
          rules="between:1,100"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="rate"
              v-model.number="form.rate"
              :state="errors[0] ? false : null"
              placeholder="1-100"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="PercentIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </validation-provider>

        <b-form-text>
          ตัวอย่าง : <br>ถ้าตั้งไว้ที่ 90% แล้วยอดค่าธรรมเนียมในเดือนนั้น ๆ
          รวมกันของตัวแทน A เป็น 1,000,000 บาท ตัวแทน A จะได้ส่วนแบ่ง 900,000
          บาท
        </b-form-text>
        <br>
        <div class="d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="hideModal"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary"
            type="submit"
          >
            ยืนยัน
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { between } from '@validations'

export default {
  data() {
    return {
      between,
      form: {
        rate: 1,
      },
    }
  },
  computed: {
    ...mapState({
      isApprovingRequest: (state) => state.request.isApprovingRequest,
    }),
    ...mapGetters([]),
    requestId() {
      const { query } = this.$route
      return query?.id || ''
    },
  },
  methods: {
    ...mapActions(['approveRequest']),
    hideModal() {
      this.$bvModal.hide('approve-request-modal')
    },
    onSubmit() {
      this.$refs.approveRequestForm.validate().then(async (success) => {
        if (success) {
          this.form.rate = +this.form.rate
          this.approveRequest({
            id: this.requestId,
            data: this.form,
          })
          this.hideModal()
        }
      })
    },
  },
}
</script>
